<template>
  <div class="body-wrap">
    <div id="mini_wrap">
      <div class="minigame_menu">
        <img src="@/assets/img/mini_title.png">
        <div class="mini_list">
          <a class="active">
            <div class="js-powerball-menu-container">
              <span class="css-powerball-img"></span>
              <span class="js-countdown-powerball">04:50</span>
              <span><input type="button" value="접속"></span>
            </div>
          </a>
          <a class="disable">
            <div class="js-powerladder-menu-container">
              <span class="css-powerladder-img"></span>
              <span class="js-countdown-powerball">04:50</span>
              <span><input type="button" value="접속"></span>
            </div>
          </a>
          <a class="disable">
            <div class="js-speedkeno-menu-container">
              <span class="css-speedkeno-img"></span>
              <span class="js-countdown-powerball">04:50</span>
              <span><input type="button" value="접속"></span>
            </div>
          </a>
          <a class="disable">
            <div class="js-kenoladder-menu-container">
              <span class="css-kenoladder-img"></span>
              <span class="js-countdown-powerball">04:50</span>
              <span><input type="button" value="접속"></span>
            </div>
          </a>
        </div>
      </div>
      <div class="mini_midwrapbox">
        <div class="games-wrap" id="mini_boxwrap">
          <div id="mini_box_right">
            <div class="mini_gamemovie">
              <div class="power_ladder">
                <iframe src="https://minigames.imgforboard.com/mini-games/iframe/4/kr" scrolling="no"></iframe>
              </div>
            </div>
            <div class="mini_timecount">
              <span id="last-play-date">{{today}}</span>
              <font>[<span id="play_num_view">{{thousand(gameRound)}}</span> 회차]</font>
              <span id="last_play_time">{{gameTime}}</span>
              <b class="count" id="remaind_time">추첨중</b>
              <em @click="reload">새로고침</em>
            </div>
            <ul class="powerball_bettingbtn">
              <li>
                <dl>
                  <dd class="game-odd-active p-odd" :class="{'active': model.noe == 'o'}" @click="onSelect('noe', 'o')">
                    <label><img src="@/assets/img/mini_btn_pb_odd.png"><div></div></label>
                    <dfn>{{rate.noe ? rate.noe.o : 0}}</dfn>
                  </dd>
                  <dd class="game-odd-active p-even"  :class="{'active': model.noe == 'e'}" @click="onSelect('noe', 'e')">
                    <label><img src="@/assets/img/mini_btn_pb_even.png"><div></div></label>
                    <dfn>{{rate.noe ? rate.noe.e : 0}}</dfn>
                  </dd>
                </dl>
                <em>게임 1</em>
                <span>일반볼 홀/짝</span>
              </li>
              <li>
                <dl>
                  <dd class="game-odd-active p-odd" :class="{'active': model.poe == 'o'}" @click="onSelect('poe', 'o')">
                    <label><img src="@/assets/img/mini_btn_pb_odd.png"><div></div></label>
                    <dfn>{{rate.poe ? rate.poe.o : 0}}</dfn>
                  </dd>
                  <dd class="game-odd-active p-even" :class="{'active': model.poe == 'e'}" @click="onSelect('poe', 'e')">
                    <label><img src="@/assets/img/mini_btn_pb_even.png"><div></div></label>
                    <dfn>{{rate.poe ? rate.poe.e : 0}}</dfn>
                  </dd>
                </dl>
                <em>게임 2</em>
                <span>파워볼 홀/짝</span>
              </li>
              <li class="wide last">
                <dl>
                  <dd class="game-odd-active p-between-15" :class="{'active': model.narea == 's'}" @click="onSelect('narea', 's')">
                    <label><img src="@/assets/img/mini_btn_pb_small.png"><div></div></label>
                    <dfn>{{rate.narea ? rate.narea.s : 0}}</dfn>
                  </dd>
                  <dd class="game-odd-active p-between-65" :class="{'active': model.narea == 'm'}" @click="onSelect('narea', 'm')">
                    <label><img src="@/assets/img/mini_btn_pb_middle.png"><div></div></label>
                    <dfn>{{rate.narea ? rate.narea.m : 0}}</dfn>
                  </dd>
                  <dd class="game-odd-active p-between-81" :class="{'active': model.narea == 'l'}" @click="onSelect('narea', 'l')">
                    <label><img src="@/assets/img/mini_btn_pb_big.png"><div></div></label>
                    <dfn>{{rate.narea ? rate.narea.l : 0}}</dfn>
                  </dd>
                </dl>
                <em>게임 3</em>
                <span>일반볼 구간</span>
              </li>
            </ul>
            <ul class="powerball_bettingbtn">
              <li class="one">
                <dl class="three">
                  <dd class="game-odd-active p-odd-over" :class="{'active': model.ncoe == 'oo'}" @click="onSelect('ncoe', 'oo')">
                    <label><img src="@/assets/img/oddover.png"><div></div></label>
                    <dfn>{{rate.ncoe ? rate.ncoe.oo : 0}} ↑</dfn>
                  </dd>
                  <dd class="game-odd-active p-odd-under" :class="{'active': model.ncoe == 'ou'}" @click="onSelect('ncoe', 'ou')">
                    <label><img src="@/assets/img/oddunder.png"><div></div></label>
                    <dfn>{{rate.ncoe ? rate.ncoe.ou : 0}} ↓</dfn>
                  </dd>
                  <dd class="game-odd-active p-even-over" :class="{'active': model.ncoe == 'eo'}" @click="onSelect('ncoe', 'eo')">
                    <label><img src="@/assets/img/evenover.png"><div></div></label>
                    <dfn>{{rate.ncoe ? rate.ncoe.eo : 0}} ↑</dfn>
                  </dd>
                  <dd class="game-odd-active p-even-under" :class="{'active': model.ncoe == 'eu'}" @click="onSelect('ncoe', 'eu')">
                    <label><img src="@/assets/img/evenunder.png"><div></div></label>
                    <dfn>{{rate.ncoe ? rate.ncoe.eu : 0}} ↓</dfn>
                  </dd>
                </dl>
                <em>게임 4</em>
                <span>홀짝/언오버</span>
              </li>
              <li class="one">
                <dl class="three">
                  <dd class="game-odd-active p-odd-s" :class="{'active': model.ncarea == 'os'}" @click="onSelect('ncarea', 'os')">
                    <label><img src="@/assets/img/odds.png"><div></div></label>
                    <dfn>{{rate.ncoe ? rate.ncarea.os : 0}}</dfn>
                  </dd>
                  <dd class="game-odd-active p-odd-m" :class="{'active': model.ncarea == 'om'}" @click="onSelect('ncarea', 'om')">
                    <label><img src="@/assets/img/oddm.png"><div></div></label>
                    <dfn>{{rate.ncoe ? rate.ncarea.om : 0}}</dfn>
                  </dd>
                  <dd class="game-odd-active p-odd-l" :class="{'active': model.ncarea == 'ol'}" @click="onSelect('ncarea', 'ol')">
                    <label><img src="@/assets/img/oddl.png"><div></div></label>
                    <dfn>{{rate.ncoe ? rate.ncarea.ol : 0}}</dfn>
                  </dd>
                  <dd class="game-odd-active p-even-s" :class="{'active': model.ncarea == 'es'}" @click="onSelect('ncarea', 'es')">
                    <label><img src="@/assets/img/evens.png"><div></div></label>
                    <dfn>{{rate.ncoe ? rate.ncarea.es : 0}}</dfn>
                  </dd>
                  <dd class="game-odd-active p-even-s" :class="{'active': model.ncarea == 'em'}" @click="onSelect('ncarea', 'em')">
                    <label><img src="@/assets/img/evenm.png"><div></div></label>
                    <dfn>{{rate.ncoe ? rate.ncarea.em : 0}}</dfn>
                  </dd>
                  <dd class="game-odd-active p-even-l" :class="{'active': model.ncarea == 'el'}" @click="onSelect('ncarea', 'el')">
                    <label><img src="@/assets/img/evenl.png"><div></div></label>
                    <dfn>{{rate.ncoe ? rate.ncarea.el : 0}}</dfn>
                  </dd>
                </dl>
                <em>게임 5</em>
                <span>홀짝/구간</span>
              </li>
            </ul>
            <ul class="powerball_bettingbtn">
              <li class="one">
                <dl class="ballnum">

                  <!--template v-for="index in 10" v-bind:key="index">
                    <dd class="game-odd-active" :class="['p-num' + (index), model.pnum == '1' ? 'active':'']" @click="onSelect('pnum', index)">
                      <label><img :src="require('@/assets/img/balls'+index+'.png')"><div></div></label>
                      <dfn>{{rate.pnum ? rate.pnum[(index-1)] : 0}}</dfn>
                    </dd>
                  </template-->

                  <dd class="game-odd-active p-num1" :class="{'active': model.pnum === '1'}" @click="onSelect('pnum', '1')">
                    <label><img src="@/assets/img/balls1.png"><div></div></label>
                    <dfn>{{rate.pnum ? rate.pnum[(index-1)] : 0}}</dfn>
                  </dd>
                  <dd class="game-odd-active p-num2" :class="{'active': model.pnum === '2'}" @click="onSelect('pnum', '2')">
                    <label><img src="@/assets/img/balls2.png"><div></div></label>
                    <dfn>{{rate.pnum ? rate.pnum[(index-1)] : 0}}</dfn>
                  </dd>
                  <dd class="game-odd-active p-num3" :class="{'active': model.pnum === '3'}" @click="onSelect('pnum', '3')">
                    <label><img src="@/assets/img/balls3.png"><div></div></label>
                    <dfn>{{rate.pnum ? rate.pnum[(index-1)] : 0}}</dfn>
                  </dd>
                  <dd class="game-odd-active p-num4" :class="{'active': model.pnum === '4'}" @click="onSelect('pnum', '4')">
                    <label><img src="@/assets/img/balls4.png"><div></div></label>
                    <dfn>{{rate.pnum ? rate.pnum[(index-1)] : 0}}</dfn>
                  </dd>
                  <dd class="game-odd-active p-num5" :class="{'active': model.pnum === '5'}" @click="onSelect('pnum', '5')">
                    <label><img src="@/assets/img/balls5.png"><div></div></label>
                    <dfn>{{rate.pnum ? rate.pnum[(index-1)] : 0}}</dfn>
                  </dd>
                  <dd class="game-odd-active p-num6" :class="{'active': model.pnum === '6'}" @click="onSelect('pnum', '6')">
                    <label><img src="@/assets/img/balls6.png"><div></div></label>
                    <dfn>{{rate.pnum ? rate.pnum[(index-1)] : 0}}</dfn>
                  </dd>
                  <dd class="game-odd-active p-num7" :class="{'active': model.pnum === '7'}" @click="onSelect('pnum', '7')">
                    <label><img src="@/assets/img/balls7.png"><div></div></label>
                    <dfn>{{rate.pnum ? rate.pnum[(index-1)] : 0}}</dfn>
                  </dd>
                  <dd class="game-odd-active p-num8" :class="{'active': model.pnum === '8'}" @click="onSelect('pnum', '8')">
                    <label><img src="@/assets/img/balls8.png"><div></div></label>
                    <dfn>{{rate.pnum ? rate.pnum[(index-1)] : 0}}</dfn>
                  </dd>
                  <dd class="game-odd-active p-num9" :class="{'active': model.pnum === '9'}" @click="onSelect('pnum', '9')">
                    <label><img src="@/assets/img/balls9.png"><div></div></label>
                    <dfn>{{rate.pnum ? rate.pnum[(index-1)] : 0}}</dfn>
                  </dd>
                  <dd class="game-odd-active p-num10" :class="{'active': model.pnum === '10'}" @click="onSelect('pnum', '10')">
                    <label><img src="@/assets/img/balls10.png"><div></div></label>
                    <dfn>{{rate.pnum ? rate.pnum[(index-1)] : 0}}</dfn>
                  </dd>
                </dl>
                <em>게임 6</em>
                <span>파워볼 숫자</span>
              </li>
              <li class="two">
                <dl>
                  <dd class="game-odd-active p-between0" :class="{'active': model.parea === 'A'}" @click="onSelect('parea', 'A')">
                    <label><img src="@/assets/img/mini_btn_pb_a.png"><div></div></label>
                    <dfn>{{rate.parea ? rate.parea.A : 0}}</dfn>
                  </dd>
                  <dd class="game-odd-active p-between-3" :class="{'active': model.parea === 'B'}" @click="onSelect('parea', 'B')">
                    <label><img src="@/assets/img/mini_btn_pb_b.png"><div></div></label>
                    <dfn>{{rate.parea ? rate.parea.B : 0}}</dfn>
                  </dd>
                  <dd class="game-odd-active p-between-5" :class="{'active': model.parea === 'C'}" @click="onSelect('parea', 'C')">
                    <label><img src="@/assets/img/mini_btn_pb_c.png"><div></div></label>
                    <dfn>{{rate.parea ? rate.parea.C : 0}}</dfn>
                  </dd>
                  <dd class="game-odd-active p-between-7" :class="{'active': model.parea === 'D'}" @click="onSelect('parea', 'D')">
                    <label><img src="@/assets/img/mini_btn_pb_d.png"><div></div></label>
                    <dfn>{{rate.parea ? rate.parea.D : 0}}</dfn>
                  </dd>
                </dl>
                <em>게임 7</em>
                <span>파워볼 구간</span>
              </li>
              <li class="two">
                <dl>
                  <dd class="game-odd-active p-under-odd-4" :class="{'active': model.pcoeuo == 'ou'}" @click="onSelect('pcoeuo', 'ou')">
                    <label><img src="@/assets/img/mini_btn_pb_odd_under.png"><div></div></label>
                    <dfn>{{rate.parea ? rate.pcoeuo.ou : 0}}</dfn>
                  </dd>
                  <dd class="game-odd-active p-over-odd-4" :class="{'active': model.pcoeuo == 'oo'}" @click="onSelect('pcoeuo', 'oo')">
                    <label><img src="@/assets/img/mini_btn_pb_even_under.png"><div></div></label>
                    <dfn>{{rate.parea ? rate.pcoeuo.oo : 0}}</dfn>
                  </dd>
                  <dd class="game-odd-active p-under-even-4" :class="{'active': model.pcoeuo == 'eu'}" @click="onSelect('pcoeuo', 'eu')">
                    <label><img src="@/assets/img/mini_btn_pb_odd_over.png"><div></div></label>
                    <dfn>{{rate.parea ? rate.pcoeuo.eu : 0}}</dfn>
                  </dd>
                  <dd class="game-odd-active p-over-even-4" :class="{'active': model.pcoeuo == 'eo'}" @click="onSelect('pcoeuo', 'eo')">
                      <label><img src="@/assets/img/mini_btn_pb_even_over.png"><div></div></label>
                      <dfn>{{rate.parea ? rate.pcoeuo.eo : 0}}</dfn>
                    </dd>
                </dl>
                <em>게임 8</em>
                <span>파워볼 언오버</span>
              </li>
            </ul>
            <dl class="mini_bettingslip" id="games-betslip">
              <dt class="col full cart_info">
                <div>
                  <em>게임분류</em>
                  <span class="f_yellow">파워볼게임</span>
                </div>
                <div id="selBet">
                  <em>게임선택</em>
                  <span class="tx">
                    <template v-for="(item, index) in totalResult" v-bind:key="index">
                      <img :src="item" style="width: 40px;height: 40px">
                    </template>
                  </span>
                </div>
                <div id="betRate">
                  <em>배당</em>
                  <span id="bet-odd">{{totalRate}}</span>
                </div>
              </dt>
              <dd>
                <h2>배팅 금액을 선택하세요</h2>
                <ul>
                  <li>
                    <div class="bet-money">
                    <input name="bet-amount-view" class="bet-amount" v-model="bettingAmtStr" readonly="" type="text">
                    <span>배팅 금액</span>
                    </div>
                    <div>
                    <input class="bet-dividend-view mini_betting_input_hit" v-model="targetMoney" readonly="" type="text">
                    <span>적중 예상금액</span>
                    </div>
                  </li>
                  <li>
                    <code data-value="5,000" class="select-amount" @click="bettingAmt = bettingAmt + 5000">5,000</code>
                    <code data-value="10,000" class="select-amount" @click="bettingAmt = bettingAmt + 10000">10,000</code>
                    <code data-value="50,000" class="select-amount" @click="bettingAmt = bettingAmt + 50000">50,000</code>
                    <code data-value="100,000" class="select-amount" @click="bettingAmt = bettingAmt + 100000">100,000</code>
                    <code data-value="500,000" class="select-amount" @click="bettingAmt = bettingAmt + 500000">500,000</code>
                    <code data-value="1,000,000" class="select-amount" @click="bettingAmt = bettingAmt + 1000000">1,000,000</code>
                  </li>
                  <li>
                    <code class="mini_betcalc_btn2 select-amount" data-value="0" @click="getRemainMoney()">잔돈</code>
                    <code class="mini_betcalc_btn3 reset-selected-amount" @click="bettingAmt = 0">초기화</code>
                    <code class="mini_betcalc_btn2 select-amount max-amount" @click="bettingAmt = userData.cashAmt">
                    최대 </code>
                    <div>
                      <input name="betExp4" class="select-amount-text" type="number" style="width:185px" v-model="inputMoney" @input="getDirectInput">
                      <span>직접 입력</span>
                    </div>
                  </li>
                </ul>
                <div class="mini_betting_confirm place-bet" @click="setBetting">배팅하기</div>
              </dd>
              <div class="bet-disable  hidden">지금 배팅을 할 수는 없습니다..
              </div>
            </dl>
            <div class="powerball_result_list mini_table powerball_result_list_inner" style="margin-bottom:30px">
              <h2 class="powerball">전체회차결과보기</h2>
              <table id="power-ball-table">
                <colgroup>
                  <col width="87px">
                  <col width="198px">
                  <col width="79px">
                  <col width="113px">
                  <col width="73px">
                  <col width="138px">
                  <col width="130px">
                  <col width="128px">
                </colgroup>
                <thead class="powerball">
                  <tr>
                    <td scope="col">회차</td>
                    <td scope="col">당첨번호결과</td>
                    <td scope="col">숫자 합</td>
                    <td scope="col">홀/짝</td>
                    <td scope="col">파워볼</td>
                    <td scope="col">파워볼 홀/짝</td>
                    <td scope="col">대/중/소</td>
                    <td scope="col">파워볼 구간</td>
                  </tr>
                  </thead>
                  <tbody class="powerball">
                  <template v-if="resultList.length > 0">
                    <template v-for="item in resultList" v-bind:key="item.gameSeq">
                      <tr class="game_no_259">
                        <td class="num  last-game-id ">{{item.gameRound}}</td>
                        <td class="result">{{item.result}}</td>
                        <td class="total">{{item.resultSum}}</td>
                        <td :class="[item.resultOE === 'o'? 'pb_odev' :'oddeven']">{{item.resultOE == 'o' ? '홀':'짝'}}</td>
                        <td class="pb">{{item.powerball}}</td>
                        <td :class="[item.powerOE === 'o'? 'pb_odev' :'oddeven']">{{item.powerOE == 'o' ? '홀':'짝'}}</td>
                        <td class="djs">
                          <template v-if="item.resultArea === 's'">
                            소
                          </template>
                          <template v-else-if="item.resultArea === 'm'">
                            중
                          </template>
                          <template v-else>
                            대
                          </template>
                        </td>
                        <td class="pb_section">{{item.powerArea}}</td>
                      </tr>
                    </template>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="8">
                        내역이 없습니다.
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <div class="powerball_result_list mini_table powerball_result_list_inner" style="margin-bottom:30px">
              <h2 class="powerball">베팅내역</h2>
              <table id="power-ball-table">
                <colgroup>
                  <col width="41px">
                  <col width="71px">
                  <col width="108px">
                  <col width="111px">
                  <col width="134px">
                  <col width="113px">
                  <col width="71px">
                  <col width="95px">
                  <col width="106px">
                  <col width="96px">
                </colgroup>
                <thead class="powerball">
                  <tr>
                    <td scope="col"></td>
                    <td scope="col">번호</td>
                    <td scope="col">회차</td>
                    <td scope="col">배팅시간</td>
                    <td scope="col">게임 분류</td>
                    <td scope="col">베팅내역</td>
                    <td scope="col">배당</td>
                    <td scope="col">배팅 금액</td>
                    <td scope="col">승/패</td>
                    <td scope="col">승리</td>
                  </tr>
                </thead>
                <tbody class="betting_history">
                  <template v-if="betList.length > 0">
                    <template v-for="item in betList" v-bind:key="item.betIdx">
                      <tr>
                        <td class="chk"><input type="checkbox" v-model="item.checked"/></td>
                        <td class="num">{{item.betIdx}}</td>
                        <td class="date">{{item.data.gameRound}}</td>
                        <td class="time">{{dateFormat(item.regDate)}}</td>
                        <td class="sort"><b>{{item.gameName}}</b></td>
                        <td class="state">
                          <img :src="item.betDetail" style="width: 40px;height: 40px"/></td>
                        <td class="per">{{item.betRate}}</td>
                        <td class="money01 td_right">{{thousand(item.betAmt)}}</td>
                        <td class="money02 td_right">{{item.resultText}}</td>
                        <td class="result "></td>
                      </tr>
                    </template>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="10">베팅내역이 없습니다. </td>
                    </tr>
                  </template>
                </tbody>
              </table>
              <h3>
                <code class="btn-history-delete-selected">선택 삭제</code>
                <code class="btn-history-select-all" @click="onAllCheck('betList')">전체 선택</code>
                <code class="btn-view-allhistory" @click="goPageByName('bettingList')">모든 배팅 내역</code>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getAuthToken } from '@/libs/auth-helper'
import { SOCKET_POWERBALL_SUBSCRIBE_DESTINATION, SOCKET_SERVER_URL } from '@/libs/constants'
import SockJS from 'sockjs-client'
import Stomp from 'webstomp-client'
import { getDateStr, getDateStrKorean, getSubDaysDate, thousand } from '@/libs/utils'
import { powerballBetting, powerballResult } from '@/api/game'

export default {
  name: 'Powerball',
  created () {
    this.socketConnect()
    this.getResult()
    this.getBettingList()
  },
  watch: {
    bettingAmt () {
      if (this.bettingAmt > this.userData.cashAmt) {
        this.onCheck('보유한 캐시보다 많습니다. ')
        this.bettingAmt = 0
      }
    },
    betList () {
      this.betList.forEach(item => {
        item.data = JSON.parse(item.betData)
        if (item.betStatus === 'WAIT') {
          item.resultText = '추첨 중'
        } else {
          if (item.betResults === 'FAIL') {
            item.resultText = '패'
          } else {
            item.resultText = '승'
          }
        }
        const key = Object.keys(item.data.betGameInfo[0])[0]
        const bet = item.data.betGameInfo[0][key]
        item.gameName = this.gameName[key]
        item.betDetail = this.image[key][bet]
      })
    }
  },
  computed: {
    bettingAmtStr () {
      return thousand(this.bettingAmt)
    },
    targetMoney () {
      return thousand(this.bettingAmt * this.totalRate)
    },
    totalRate () {
      let total = 1
      for (const game in this.model) {
        const value = this.model[game]
        const rate = Number(this.rate[game][value])
        if (rate > 0) {
          total = total * rate
        }
      }
      return total.toFixed(2)
    },
    totalResult () {
      const result = []
      for (const game in this.model) {
        const value = this.model[game]
        const image = this.image[game][value]
        if (image) {
          result.push(image)
        }
      }
      return result
    }
  },
  methods: {

    getBettingList () {
      this.onBettingList(this.pageInfo.page, 'powerball')
    },
    getResult () {
      powerballResult({}).then(response => {
        const result = response.data
        if (result.resultCode === '0') {
          const data = result.data
          this.resultList = data.list
        }
      })
    },
    reload () {
      location.reload()
    },
    async setBetting () {
      let emptyCount = 0
      for (const key in this.model) {
        if (this.model[key] === '') {
          emptyCount++
        }
      }

      if (emptyCount === Object.keys(this.model).length) {
        this.onCheck('게임을 1개이상 배팅해주세요.')
        return false
      }

      if (this.bettingAmt <= 0) {
        this.onCheck('배팅금액을 입력해주세요.')
        return false
      }
      const params = {
        gameRound: this.gameRound.toString(),
        betAmt: this.bettingAmt,
        betGameInfo: [
          this.model
        ],
        groupCode: 'minigame',
        gameType: 'powerball',
        betRate: this.totalRate
      }
      const confirm = await this.onConfirm('배팅하시겠습니까?')
      if (confirm) {
        powerballBetting(params).then(async response => {
          const result = response.data
          if (result.resultCode === '0') {
            this.onCheck('배팅이 완료되었습니다.')
          } else {
            this.onCheck('api.' + result.resultCode)
          }
        })
      }
    },
    getDirectInput (value) {
      this.bettingAmt = this.inputMoney
    },
    getRemainMoney () {
      const point = 6
      const cash = this.userData.cashAmt.toString()
      const length = cash.length

      if (length <= point) {
        this.onCheck('잔돈은 십만단위이하입니다.')
      } else {
        this.bettingAmt = Number(cash.slice(length - point, length))
      }
    },
    onSelect (game, value) {
      this.model = {}
      this.model[game] = value
      // if (value === this.model[game]) {
      //   this.model[game] = ''
      // } else {
      //   this.model[game] = value
      // }
    },
    socketConnect (type = 'member', chatId = '1') {
      const token = getAuthToken()
      if (!token) {
        if (this.stompClient) {
          this.stompClient.client.disconnect()
        }
      }
      if (token && !this.stompClient) {
        this.stompClient = {
          client: null,
          token: token,
          type: type,
          chatId: chatId
        }
        const serverURL = SOCKET_SERVER_URL
        const socket = new SockJS(serverURL)
        this.stompClient.client = Stomp.over(socket, { debug: false })
        this.stompClient.client.connect(
          {},
          frame => {
            // 소켓 연결 성공
            this.isConnectSocket = true
            // 서버의 메시지 전송 endpoint를 구독합니다.
            // 이런형태를 pub sub 구조라고 합니다.
            this.stompClient.client.subscribe(SOCKET_POWERBALL_SUBSCRIBE_DESTINATION + this.userData.siteId, res => {
              const jsonData = JSON.parse(res.body)
              let content = jsonData.content || null
              if (content) {
                content = JSON.parse(content)
                for (let i = 0, iLen = content.length; i < iLen; i++) {
                  const item = content[i]

                  if (item.gameUseYn === 'Y') {
                    this.gameRound = item.gameRound
                    this.gameTime = item.gameTime
                    const gameName = item.optionSubGroup
                    const type = item.option

                    if (this.rate[gameName]) {
                      this.rate[gameName][type] = item.betRate
                      this.rate[gameName].info = item
                    }
                  }
                }
              }
            })
          },
          error => {
            // 소켓 연결 실패
            console.error(error)
            this.isConnectSocket = false
          }
        )
      }
    }
  },
  data () {
    return {
      searchDate: {
        startDate: getDateStr(getSubDaysDate(new Date(), 7), 'yyyy-MM-dd 00:00:00'),
        endDate: getDateStr(new Date(), 'yyyy-MM-dd 23:59:59')
      },
      betList: [],
      resultList: [],
      today: getDateStrKorean(new Date()),
      gameRound: '',
      gameTime: '',
      isConnectSocket: false,
      stompClient: null,
      bettingAmt: 0,
      inputMoney: 0,
      model: {

      },
      rate: {
        noe: {},
        poe: {},
        narea: {},
        ncoe: {},
        ncarea: {},
        pnum: {},
        parea: {},
        pcoeuo: {}
      },
      gameName: {
        noe: '일반볼 홀/짝',
        poe: '파워볼 홀/짝',
        narea: '일반볼 구간',
        ncoe: '일반볼 홀짝/언오버',
        ncarea: '일반볼 홀짝/구간',
        pnum: '파워볼 숫자',
        parea: '파워볼 구간',
        pcoeuo: '파워볼 홀짝/언오버'
      },
      image: {
        noe: {
          o: require('@/assets/img/mini_btn_pb_odd.png'),
          e: require('@/assets/img/mini_btn_pb_even.png')
        },
        poe: {
          o: require('@/assets/img/mini_btn_pb_odd.png'),
          e: require('@/assets/img/mini_btn_pb_even.png')
        },
        narea: {
          s: require('@/assets/img/mini_btn_pb_small.png'),
          m: require('@/assets/img/mini_btn_pb_middle.png'),
          l: require('@/assets/img/mini_btn_pb_big.png')
        },
        ncoe: {
          oo: require('@/assets/img/oddover.png'),
          ou: require('@/assets/img/oddunder.png'),
          eo: require('@/assets/img/evenover.png'),
          eu: require('@/assets/img/evenunder.png')
        },
        pnum: {
          1: require('@/assets/img/balls1.png'),
          2: require('@/assets/img/balls2.png'),
          3: require('@/assets/img/balls3.png'),
          4: require('@/assets/img/balls4.png'),
          5: require('@/assets/img/balls5.png'),
          6: require('@/assets/img/balls6.png'),
          7: require('@/assets/img/balls7.png'),
          8: require('@/assets/img/balls8.png'),
          9: require('@/assets/img/balls9.png'),
          10: require('@/assets/img/balls10.png')
        },
        ncarea: {
          os: require('@/assets/img/odds.png'),
          om: require('@/assets/img/oddm.png'),
          ol: require('@/assets/img/oddl.png'),
          es: require('@/assets/img/evens.png'),
          em: require('@/assets/img/evenm.png'),
          el: require('@/assets/img/evenl.png')
        },
        parea: {
          A: require('@/assets/img/mini_btn_pb_a.png'),
          B: require('@/assets/img/mini_btn_pb_b.png'),
          C: require('@/assets/img/mini_btn_pb_c.png'),
          D: require('@/assets/img/mini_btn_pb_d.png')
        },
        pcoeuo: {
          ou: require('@/assets/img/mini_btn_pb_odd_under.png'),
          oo: require('@/assets/img/mini_btn_pb_even_under.png'),
          eu: require('@/assets/img/mini_btn_pb_odd_over.png'),
          eo: require('@/assets/img/mini_btn_pb_even_over.png')
        }
      }
    }
  }
}
</script>
<style scoped>
.body-wrap {width: 1140px;margin: 0 auto;background: #fff;padding-top: 180px;}
#mini_wrap {width: 1100px;margin: 0 auto;}
.minigame_menu {border: inherit;overflow: hidden;}
.minigame_menu>.mini_list {width: 860px;height: 54px;line-height: 54px;border: none;box-sizing: border-box;text-align: right;float: right;}
.minigame_menu>.mini_list>a {display: inline-block;float: left;width: 25%;margin-left: 0;box-sizing: border-box;font-size: 16px;color: #5e7d9b;}
.minigame_menu>.mini_list>a.active {color: #ca9423;text-decoration: inherit;}
.minigame_menu>.mini_list>a.active>div {background: #1c3d51;border-top-color: #ff7f00;}
.minigame_menu .mini_list a div>span:last-child {float: left;line-height: 20px;margin-top: 6px;}
.minigame_menu>.mini_list>a.disable input {background: linear-gradient(to bottom,#c1bab6 0%,#9a8984 100%);border: 1px solid #6f6462;pointer-events: none;}
.minigame_menu>.mini_list>a>.js-powerball-menu-container, .minigame_menu>.mini_list>a>.js-powerladder-menu-container, .minigame_menu>.mini_list>a>.js-speedkeno-menu-container, .minigame_menu>.mini_list>a>.js-kenoladder-menu-container, .minigame_menu>.mini_list>a>.js-fx1-menu-container {
    background: #222c36;border-top: 2px solid #0583bd;border-right: 1px solid #0583bd;height: 52px;}
.minigame_menu>.mini_list>a .css-powerball-img, .minigame_menu>.mini_list>a .css-powerladder-img, .minigame_menu>.mini_list>a .css-speedkeno-img, .minigame_menu>.mini_list>a .css-kenoladder-img, .minigame_menu>.mini_list>a .css-fx1-img, .minigame_menu>.mini_list>a .css-fx-ladder1-img {
    background-image: url("~@/assets/img/minigames-logo-icon01.png");background-position: center center;background-size: contain;background-repeat: no-repeat;float: left;height: 51px;width: 45px;margin-left: 5px;margin-right: 5px;}
.js-countdown-powerball, .js-countdown-powerladder, .js-countdown-speedkeno, .js-countdown-kenoladder, .js-countdown-fx1, .js-countdown-fxladder1 {
    background-image: url("~@/assets/img/minigames-clock-icon.png");background-repeat: no-repeat;background-position: 0 center;color: #fff600;float: left;font-size: 22px;font-weight: 600;padding-left: 20px;text-align: left;text-decoration: none;width: 75px;}
.js-powerball-menu-container input[type=button], .js-powerladder-menu-container input[type=button], .js-speedkeno-menu-container input[type=button], .js-kenoladder-menu-container input[type=button], .js-fx1-menu-container input[type=button] {
    background: #e94419;
    background: -moz-linear-gradient(top,#f16c35 0%,#e94419 100%);
    background: -webkit-linear-gradient(top,#f16c35 0%,#e94419 100%);
    background: linear-gradient(to bottom,#f16c35 0%,#e94419 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e94419',endColorstr='#f16c35',GradientType=0 );
    border: 1px solid #ab3112;
    border-radius: 5px;
    box-shadow: inset 1px 1px 0 rgb(255 255 255 / 30%);
    color: #fff;
    font-size: 14px;
    height: 40px;
    margin-top: -1px;
    margin-left: 3px;
    margin-right: 2px;
    text-align: center;
    text-shadow: 0 1px 0 #000;
    width: 56px;
    padding: 0;}
.mini_midwrapbox {width: inherit;background: inherit;border-left: 1px solid #e7e7e7;border-right: 1px solid #e7e7e7;margin: 0 auto;min-height: 1000px;box-sizing: border-box;}
#mini_boxwrap {background-color: #fff;overflow: hidden;}
#mini_box_right {width: 987px;margin: 0 auto;min-height: 1050px;}
.mini_gamemovie, .mini_timecount {text-align: center;}
.mini_gamemovie>div.power_ladder {height: 650px;}
.mini_gamemovie>div {position: relative;width: 830px;margin: 0 auto;overflow: hidden;transform: scale(.95);}
.mini_gamemovie>div.power_ladder>iframe {left: 0;top: 0;width: 830px;height: 650px;}
.mini_gamemovie>div>iframe {position: absolute;overflow: hidden;}
.mini_timecount {position: relative;background: inherit;border-top: 2px solid #858585;border-bottom: 1px solid #d2d2d2;position: relative;height: 37px;padding-top: 15px;font-size: 17px;}
.mini_timecount>font {display: inline-block;margin: 0 5px;color: #e77727;}
.mini_timecount>b {display: inline-block;margin-left: 10px;font-size: 19px;color: #959595;}
.mini_timecount>em {position: absolute;right: 15px;top: 15px;display: block;font-size: 12px;background: #555;color: #fff;border-radius: 5px;}
.mini_timecount>em, .timer_box>em {padding: 5px 10px;transition: .3s;cursor: pointer;}
.powerball_bettingbtn {overflow: hidden;}
.powerball_bettingbtn>li {padding-top: 21px;height: 179px;}
.powerball_bettingbtn>li {background: linear-gradient(to top,#FFF,#e6f2f8);border-right: 1px solid #ccc;}
.powerball_bettingbtn>li {width: calc(30% - 1px);font-size: 14px;height: 185px;padding-top: 15px;}
.powerball_bettingbtn>li:last-child {border-right: none;}
.powerball_bettingbtn>li.wide {width: 40%;}
.mini_bettingbtn>li, .powerball_bettingbtn>li {transition: .5s;text-align: center;float: left;overflow: hidden;}
.powerball_bettingbtn>li>dl {margin-bottom: 13px;margin-bottom: 10px;text-align: center;}
.powerball_bettingbtn>li>dl>dd {display: inline-block;margin: 0 6px 5px;width: 88px;cursor: pointer;opacity: inherit;filter: alpha(opacity=85);transition: .2s opacity ease-out;}
.powerball_bettingbtn>li>dl>dd>label {transform: scale(.77);margin: -12px 0 5px -13px;}
.powerball_bettingbtn>li>dl>dd>label {position: relative;margin: -12px 0 5px -12px;cursor: pointer;display: inline-block;background-image: url("~@/assets/img/sprite_games_min.png");background-repeat: no-repeat;overflow: hidden;text-indent: -9999px;text-align: left;transform: scale(.75);}
.powerball_bettingbtn>li>dl>dd>label:hover {opacity: .75;}
.powerball_bettingbtn>li>dl.three>dd {margin: 0 20px 5px;}
.p-odd label {background-position: -3px -2142px;width: 115px;height: 116px;}
.p-even label {background-position: -3px -1190px;width: 115px;height: 116px;}
.p-between-15 label {background-position: -3px -2856px;width: 115px;height: 116px;}
.p-between-65 label {background-position: -3px -1904px;width: 115px;height: 116px;}
.p-between-81 label {background-position: -3px -476px;width: 115px;height: 116px;}
.p-between0 label {background-position: -3px 0;width: 115px;height: 116px;}
.p-between-3 label {background-position: -3px -238px;width: 115px;height: 116px;}
.p-between-5 label {background-position: -3px -714px;width: 115px;height: 116px;}
.p-between-7 label {background-position: -3px -952px;width: 115px;height: 116px;}
.p-under-odd-4 label {background-position: -3px -2618px;width: 115px;height: 116px;}
.p-under-even-4 label {background-position: -3px -1666px;width: 115px;height: 116px;}
.p-over-odd-4 label {background-position: -3px -2380px;width: 115px;height: 116px;}
.p-over-even-4 label {background-position: -3px -1428px;width: 115px;height: 116px;}
.p-odd-over label {background-position: -1px -4207px !important;width: 115px;height: 116px;}
.p-odd-under label {background-position: -1px -4444px !important;width: 115px;height: 116px;}
.p-even-over label {background-position: -1px -4685px !important;width: 115px;height: 116px;}
.p-even-under label {background-position: -1px -4924px !important;width: 115px;height: 116px;}
.p-odd-s label {background-position: -3px -5289px !important;width: 115px;height: 116px;}
.p-odd-m label {background-position: -3px -5525px !important;width: 115px;height: 116px;}
.p-odd-l label {background-position: -3px -5761px !important;width: 115px;height: 116px;}
.p-even-s label {background-position: -3px -5875px !important;width: 115px;height: 116px;}
.p-even-m label {background-position: -3px -6111px !important;width: 115px;height: 116px;}
.p-even-l label {background-position: -3px -6349px !important;width: 115px;height: 116px;}
.p-num1 label {background-position: -3px 0px !important;width: 115px;height: 116px;}
.p-num2 label {background-position: -3px -118px !important;width: 115px;height: 116px;}
.p-num3 label {background-position: -3px -240px !important;width: 115px;height: 116px;}
.p-num4 label {background-position: -3px -360px !important;width: 115px;height: 116px;}
.p-num5 label {background-position: -3px -479px !important;width: 115px;height: 116px;}
.p-num6 label {background-position: -3px -599px !important;width: 115px;height: 116px;}
.p-num7 label {background-position: -3px -719px !important;width: 115px;height: 116px;}
.p-num8 label {background-position: -3px -839px !important;width: 115px;height: 116px;}
.p-num9 label {background-position: -3px -959px !important;width: 115px;height: 116px;}
.p-num10 label {background-position: -3px -1079px !important;width: 115px;height: 116px;}
*[class*=p-ball_opti] label {background-position: -3px -3094px;width: 100px;height: 101px;}
.powerball_bettingbtn>li>dl>dd>label>img {width: 88px;}
.powerball_bettingbtn>li>dl>dd>dfn {margin-top: -17px;color: #595959;display: block;cursor: pointer;}
.powerball_bettingbtn>li>em {background: #54a9d0;border: 2px solid #54a9d0;color: #fff;padding: 4px 10px;display: inline-block;line-height: 100%;border-radius: 20px;font-size: 12px;margin-right: 5px;}
.powerball_bettingbtn>li.one {border-top: 1px solid #ccc;width: 100%;}
.powerball_bettingbtn>li.one dl {/*margin-bottom: -1px;*/}
.powerball_bettingbtn>li.one>dl>dd {padding-top: 15px;height: 115px;width: 77px;}
.powerball_bettingbtn>li.one>dl>dd>label {margin-left: -14px;background: url("~@/assets/img/mini_btn_pb_bg.png");background-position: 3px -1px;background-repeat: no-repeat;transform: scale(.88);transform-origin: 100px 80px;margin-bottom: 15px;margin-left: -20px;}
.powerball_bettingbtn>li.one>dl>dd>label {text-align: center;font-weight: 400;font-size: 38px;color: #010101;line-height: 90px;text-indent: 0;}
.powerball_bettingbtn>li.one>dl.three>dd>label {background: url("~@/assets/img/sprite_games_min.png");text-indent: -9999px;}
.powerball_bettingbtn>li.one>dl.ballnum>dd>label {background: url("~@/assets/img/sprite_num.png");text-indent: -9999px;}
.powerball_bettingbtn>li.two {border-top: 1px solid #ccc;width: calc(50% - 1px);}
.powerball_bettingbtn>li.two>dl>dd {margin: 0 8px 5px;}
.powerball_bettingbtn>li>dl>dd.active>label>div, .powerball_bettingbtn>li.one>dl>dd.active>label>div, .powerball_bettingbtn>li.one>dl.three>dd.active>label>div {position: absolute;left: 0;top: 1px;width: 98px;height: 98px;border: 8px solid #3a9c2d;border-radius: 50%;}
.powerball_bettingbtn>li.one>dl>dd.active>label>div {top: 0;width: 82px;height: 80px;left: 2px;}
.powerball_bettingbtn>li.one>dl.ballnum>dd.active>label>div {top: 13px;width: 82px;height: 80px;left: 6px;}
.mini_bettingslip {border-top: 2px solid #858585;border-bottom: 1px solid #e7e7e7;margin-bottom: 20px;overflow: hidden;position: relative;}
.mini_bettingslip>dt {border-right: 1px solid #ccc;}
.mini_bettingslip>dd, .mini_bettingslip>dt {background: #fff;}
.mini_bettingslip>dd {float: left;width: 73%;padding-left: 2%;height: 200px;overflow: hidden;}
.mini_bettingslip>dt {float: left;width: calc(25% - 1px);height: 155px;padding-top: 45px;border-right: 1px solid #ccc;}
.mini_bettingslip>dt>div {margin-bottom: 15px;overflow: hidden;font-size: 16px;}
.mini_bettingslip>dt>div>em {color: #959595;font-size: 13px;display: block;float: left;width: 80px;margin: 0 10px 0 30px;}
.mini_bettingslip>dt>div>span.f_yellow {color: #595959;font-size: 13px;}
.mini_bettingslip>dt>div>span {display: block;float: left;width: 120px;color: #595959;font-size: 13px;}
div#selBet {padding-top: 5px;height: 40px;}
.mini_bettingslip>dt>div:nth-child(2) {margin-bottom: 15px;}
.mini_bettingslip>dd>h2 {color: #595959;margin: 22px 0 10px;font-size: 14px;display: block;font-size: 17px;}
.mini_bettingslip>dd>ul {float: left;margin-right: 3px;}
.mini_bettingslip>dd>ul>li:first-child {margin-bottom: 5px;}
.mini_bettingslip>dd>ul>li {overflow: hidden;margin-bottom: 4px;}
.mini_bettingslip>dd>ul>li>div {float: left;position: relative;margin-right: 5px;}
.mini_bettingslip>dd>ul>li>div>input {font-size: 13px;background: #fafafa;border: 1px solid #737373;width: 185px;height: 36px;text-align: right;padding: 0 20px 0 80px;border-radius: 4px;}
.mini_bettingslip>dd>ul>li>div>span {font-size: 14px;top: 9px;position: absolute;left: 15px;top: 12px;color: #333;}
.mini_bettingslip>dd>ul>li>code {width: 90px;height: 27px;line-height: 21px;background: linear-gradient(to top,#AAA,#888);border: 2px solid #999;border-radius: 5px;padding-top: 6px;display: block;float: left;text-align: center;color: #fff;font-size: 14px;margin-right: 3px;cursor: pointer;}
.mini_bettingslip>dd>ul>li>code:hover {background: linear-gradient(to top,#AAA,#888);opacity: .75;}
.mini_bettingslip>dd>ul>li>code.mini_betcalc_btn2 {color: #fff;background: #62b457;border: 2px solid #62b457;}
.mini_bettingslip>dd>ul>li>code.mini_betcalc_btn3 {color: #fff;background: #3a9c2d;border: 2px solid #3a9c2d;}
.mini_bettingslip>dd>ul>li>div>input.select-amount-text {color: #df6915;}
.mini_bettingslip>dd>div.mini_betting_confirm {background: #0583bd;border: 2px solid #0583bd;border-radius: 5px;width: 114px;float: left;height: 74px;padding-top: 44px;text-align: center;font-size: 20px;color: #fff;cursor: pointer;}
.mini_bettingslip>dd>div.mini_betting_confirm:hover {background: #0583bd;opacity: .75;}
.bet-disable {padding: 68px 0;}
.hidden {background: transparent;height: 0;line-height: 0;margin: 0;overflow: hidden;padding: 0;visibility: hidden;width: 0;display: none!important;}
.bet-disable {position: absolute;top: 0;left: 0;width: 100%;height: 100%;background-color: rgba(0,0,0,.5);text-align: center;display: block;color: #fff;vertical-align: middle;font-size: 30px;padding: 95px;}
.hidden {display: none!important;}
.mini_table {padding: 0;margin-bottom: 30px;overflow: hidden;}
.mini_table>h2.powerball {margin-top: 10px;}
.mini_table>h2 {margin-bottom: 20px;padding: 10px 20px;font-size: 14px;color: #595959;width: 100%;background: linear-gradient(to top,#e6f2f8,#FFF);border-top: 2px solid #54a9d0;border-bottom: 1px solid #e7e7e7;display: block;float: left;}
.mini_table table {width: 947px;margin: 20px auto 0;border: none;}
.mini_table table>thead.powerball>tr {background-size: auto 100%;}
.mini_table table>thead>tr {border: 1px solid #e7e7e7;border-top: 2px solid #858585;background: inherit;}
.mini_table table>thead.powerball>tr>td {height: 45px;background-size: auto 100%;}
.mini_table table>thead>tr>td {font-size: 13px;color: #666;height: 66px;text-align: center;vertical-align: middle;}
.mini_table table>tbody>tr {border-bottom: 1px solid #e7e7e7;}
.mini_table table>tbody.powerball>tr>td {padding: 10px 0;}
.mini_table table>tbody>tr>td {background: inherit;border: none;color: #959595;text-align: center;font-size: 13px;}
.mini_table table>tbody.betting_history>tr>td {padding: 10px 0;font-size: 13px;height: 38px;vertical-align: middle;}
.mini_table table>tbody.powerball>tr>td.oddeven {color: #d20000;}
.mini_table table>tbody.powerball>tr>td.pb_odev {color: #0583bd;}
.mini_table>h3 {display: block;float: right;overflow: hidden;margin-top: 15px;}
.mini_table>h3>code {display: block;float: left;background: #004b8a;font-size: 13px;color: #fff;padding: 4px 15px;border-radius: 4px;margin-left: 5px;cursor: pointer;}
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
